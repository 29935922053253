:root {

  --primary-color: #5b6f56;
  --secundary-color: #bfddab;
  --menu-color: #436a2e;
  --grey-color: #606060;
  --white-color: #fff;
  --black-color: black;
  --red-color: red;
  --light-grey-color: #f6f6f6;

  --baseline: 5px;

  --max-content-width: 1350px;

}

.no-decoration {
  text-decoration: none;
}