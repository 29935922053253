.alert {
    background-color: var(--secundary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
    top: 30%;
    width: 450px;
    transition: height 0.3s ease-out;
    left: 50%;
    margin-left: -225px;
    padding: 30px 20px;
    position: fixed;
    box-sizing: border-box;
}

.hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.alert-text {
    text-align: center;
    line-height: 1.5;
    width: 80%;
}

.alert-error {
    background-color: #ddbdc7;
    color: #333;
}

.close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .alert {
        width: 300px;
        padding: 20px 15px;
        margin-left: -150px;
    }
}