.container-birthday-input .container-input {
    margin: 0;
}

.container-birthday-group {
    display: flex;
    column-gap: 5px;
}

.container-birthday-group label {
    display: none;
}