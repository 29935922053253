.login-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: calc(var(--baseline)*4);
}

.login-form {
  width: 100%;
  max-width: 350px;
}

label {
  display: block;
  margin-bottom: var(--baseline);
}

.login-form input[type="email"],
.login-form input[type="password"] {
  border: none;
  border-bottom: 1px solid var(--primary-color);
  outline: none;
  text-align: center;
}

.pass {
  color: var(--grey-color);
}

.container-pass {
  margin-top: calc(var(--baseline)*4);
  margin-bottom: calc(var(--baseline)*6);
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.banner {
  width: 100%;
  margin-top: calc(var(--baseline)*12);
}

@media screen and (max-width: 768px) {
  .banner {
    margin-top: calc(var(--baseline) * 10);
  }
}

.index-page .main {
  margin-top: 0;
}