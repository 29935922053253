.container-banner {
    background-color: #F7F5F5;
    text-align: center;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.description-title {
    font-family: "Editorial New", serif;
    font-size: 3.5em;
    line-height: 1.25em;
    margin-top: calc(var(--baseline)*3);
    margin-bottom: calc(var(--baseline)*2);
}

.description-text {
    font-size: 1.2em;
    max-width: 700px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    line-height: 25px;
}

.extend-text {
    font-size: 1.2em;
    max-width: 45%;
    text-align: center;
    margin-top: 0;
}

@media (max-width: 768px) {
    .container-banner {
        padding: 35px 20px;
    }
    .description-title {
        font-size: 2.5em;
    }
    .description-text {
        font-size: 1em;
        max-width: 90%;
        line-height: 20px;
    }
    .extend-text {
        font-size: 1em;
        max-width: 90%;
        text-align: center;
    }
}

@media (max-width: 500px) {
    .container-banner {
        padding: 30px 20px;
    }


}