@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.text-carousel-container {
  overflow: hidden;
  width: 100%;
  border-top: 2px solid var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  padding: calc(var(--baseline)*4) 0 calc(var(--baseline)*3);
}

.text-carousel {
  display: inline-block;
  width: 100%;
  font-family: "Editorial New", Georgia, "Times New Roman", serif;
  font-style: italic;
  font-size: 4rem;
  line-height: calc(var(--baseline)*14);
  animation: slide-left 15s linear infinite;
}

.text-carousel span {
  display: inline-block;
  color: var(--primary-color);
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .text-carousel-container {
    padding: calc(var(--baseline)*3) 0 calc(var(--baseline)*2);
  }
  .text-carousel {
    font-size: 3rem;
    line-height: calc(var(--baseline)*12);
    animation: slide-left 5s linear infinite;
  }
}