.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-form {
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
}

.create-text {
  text-align: center;
  margin: calc(var(--baseline)*6) 0;
}

.register-form input,
.register-form select {
  width: 100%;
}

/* Estilo para los botones */
.register-form button {
  width: 100%;
  margin-top: 15px; /* Ajusta el margen superior del botón */
}

@media (max-width: 768px) {
  .register-form {
      width: 100%;
  }
}