.label-input {
    font-size: calc(1rem/1.2);
}

.required::after {
    content: " *";
    color: red;
}

.input {
    height: calc(var(--baseline)*7);
    box-sizing: border-box;
    border-radius: 2px;
    border: 1px solid var(--grey-color);
}
.container-input {
    margin-top: calc(var(--baseline)*3);
    display: flex;
    flex-direction: column;
}

.input-wrapper {
    display: flex;
    column-gap: 5px;
}

.select-input {
    width: 35% !important;
}

.input--w-sm {
    width: 200px;
}
.input--w-md {
    width: 350px;
}
.input--w-lg {
    width: 500px;
}

.input--w-100 {
    width: 100%;
}

@media screen and (max-width: 600px) {
    .input--w-sm {
        width: 150px;
    }
    .input--w-md {
        width: 300px;
    }
    .input--w-lg {
        width: 400px;
    }
}

@media screen and (max-width: 450px) {
    .input--w-sm {
        width: 200px;
    }
    .input--w-md {
        width: 300px;
    }
    .input--w-lg {
        width: 100%;
    }
}

@media screen and (max-width: 350px) {
    .input--w-md {
        width: 100%;
    }
}