.sort-button {
    position: relative;
    text-align: right;
    display: inline-block;
}

.button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    color: var(--grey-color);
    align-items: center;
    justify-content: space-between;
}

.button .arrow {
    margin-left: 10px;
}

.menu-sort {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
    min-width: 100%;
}

.menu-item {
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
}

.menu-item:hover {
    background-color: #f0f0f0;
}

@media screen and (max-width: 768px) {
    .menu-item {
        font-size: 13px;
    }
}