.footer {
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: calc(var(--baseline)*8);
    background-color: var(--light-grey-color);
    padding: calc(var(--baseline) * 5) 0;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
}

.footer--fixed {
    position: fixed;
}

.footer-container {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.footer-text {
    font-size: 0.9rem;
    color: #333;
    font-weight: 500;
    text-align: center;
    margin: 0 0 calc(var(--baseline) * 2);
}

.footer-images-container {
    display: flex;
    column-gap: 5px;
}

.icon {
    width: 30px;
    height: 30px;
    fill: #000;
}

.icon-footer {
    height: 4em;
    padding: 4px;
}