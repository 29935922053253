html,
body {
  font-family: 'Uncut Sans', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
}

@font-face {
  font-family: 'Uncut Sans';
  src: url('Resources/Fonts/Uncut-Sans-Regular.otf');
  font-weight: normal;
}

@font-face {
  font-family: 'Uncut Sans';
  src: url('Resources/Fonts/Uncut-Sans-Medium.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Uncut Sans';
  src: url('Resources/Fonts/Uncut-Sans-Bold.otf');
  font-weight: 800;
}

@font-face {
  font-family: 'Editorial New';
  src: url('Resources/Fonts/PPEditorialNew-Ultralight.otf');
}

@font-face {
  font-family: 'Editorial New';
  src: url('Resources/Fonts/PPEditorialNew-UltralightItalic.otf');
  font-style: italic;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 16px;
}

div, p, input, select, textarea, button {
  font-family: 'Uncut Sans', Helvetica, sans-serif;
  font-size: 16px;
}

div, p {
  font-size: 16px;
  line-height: 20px;
}

.main {
  box-sizing: border-box;
  width: 100%;
  max-width: var(--max-content-width);
  margin: 0 auto;
  margin-top: calc(var(--baseline) * 12);
  padding: 0 15px;
}

@media screen and (max-width: 500px) {

  html {
    font-size: 14px;
  }

  div, p, input, select, textarea, button {
    font-size: 14px;
  }

  div, p {
    font-size: 14px;
  }

}