table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    margin-top: calc(var(--baseline)*4);
    margin-bottom: calc(var(--baseline)*5);
}

th,
td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}

th {
    font-weight: 600;
}

td code {
    word-wrap: anywhere;
}

.app-container {
    display: flex;
    justify-content: flex-end;
    margin-top: calc(var(--baseline)*5);
    margin-bottom: calc(var(--baseline)*3);
}

.expanded-icon-container {
    padding-left: 0;
    padding-right: 0;
}

.expanded-icon {
    display: block;
    cursor: pointer;
    font-size: calc(var(--baseline)*4);
}

.paginator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc(var(--baseline)*10);
}

@media screen and (max-width: 500px) {
    th, td {
        padding: 7px 5px;
        font-size: .9rem;
    }
    .app-container {
        margin-bottom: calc(var(--baseline)*2);
    }
    .expanded-icon {
        font-size: calc(var(--baseline)*3.5);
    }
}