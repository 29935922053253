.text-row {
    display: flex;
    justify-content: space-evenly;
    column-gap: 15px;
    row-gap: calc(var(--baseline)*3);
    color: var(--grey-color);
    margin: calc(var(--baseline)*3) 0 calc(var(--baseline)*6);
    flex-wrap: wrap;
}

.text-row-container {
    text-align: center;
    margin: 0;
    width: 220px;
}

@media screen and (max-width: 600px) {
    .text-row {
        margin: calc(var(--baseline)*2) 0 calc(var(--baseline)*4);
    }
    .text-row-container {
        width: 40%;
    }
}
