.container-button {
    margin-top: calc(var(--baseline)*3);
    display: flex;
    align-items: center;
}

.login-type,
.register-type {
    width: 100%;
    box-sizing: border-box;
    padding: calc(var(--baseline)*3) 40px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-type {
    color: var(--white-color);
    border: 0;
    background-color: var(--primary-color);
}

.register-type {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    background-color: var(--white-color);
}

.login-type:hover,
.register-type:hover {
    background-color: var(--secundary-color)
}