.header {
  height: calc(var(--baseline)*12);
  width: 100%;
  color: var(--black-color);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.header--fixed {
  background-color: var(--secundary-color);
  z-index: 1000;
}

.header-container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  box-sizing: border-box;
  max-width: var(--max-content-width);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-section,
.right-section {
  flex: 1;
}

.left-section {
  width: 10%;
  text-align: left;
}

.center-section {
  text-align: center;
}

.right-section {
  text-align: right;
}

.open-icon {
  height: calc(var(--baseline)*5);
  padding: calc(var(--baseline)*1);
}

header .logo {
  height: calc(var(--baseline)*5);
}

.back-link {
  text-decoration: none;
  color: var(--black-color);
}

.back-link:hover {
  border-bottom: none;
}

.menu-button {
  font-size: 2em;
}

@media screen and (max-width: 768px) {
  .header {
    height: calc(var(--baseline) * 10);
  }

  header .logo {
    height: calc(var(--baseline)*4);
  }

  .menu-button {
    text-align: right;
  }

  .center-section {
    width: 100%;
    margin-left: 35px;
  }

  .header-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .not-logged .center-section {
    margin-left: 0 !important;
  }

}