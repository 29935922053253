.container-select {
    margin-top: calc(var(--baseline)*3);
}

.container-select select {
    background-color: var(--white-color);
    border: 1px solid var(--grey-color);
    border-radius: 2px;
    padding: var(--baseline);
    width: 100%;
    height: calc(var(--baseline)*7);
    box-sizing: border-box;
}