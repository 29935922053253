.account-card-container {
    flex:1;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    column-gap: 25px;
    row-gap: calc(var(--baseline)*6);
    margin: calc(var(--baseline)*6) auto;
}

@media screen and (max-width: 768px) {
    .account-card-container {
        flex-direction: column;
        margin: calc(var(--baseline)*4) auto;
        row-gap: calc(var(--baseline)*5);
    }
}