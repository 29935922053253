.card .container-button {
    margin: calc(var(--baseline)*2) 0;
    justify-content: left;
}
.card button {
    max-width: 280px;
}
.card-title p {
    font-size: 2.07rem;
    line-height: calc(var(--baseline)*8);
    margin: 0;
}
.card-text-info {
    color: var(--grey-color);
}

@media screen and (max-width: 768px) {

    .card-title {
        display: flex;
        column-gap: 20px;
    }

    .card-text-info {
        margin: calc(var(--baseline)*1) 0 calc(var(--baseline)*4);
    }

    .card button {
        max-width: 280px;
        padding: calc(var(--baseline)*2) 30px;
    }

}

@media screen and (max-width: 500px) {

    .card-title p {
        font-size: 1.73rem;
    }

}