.main-home {
    margin-top: calc(var(--baseline)*4) !important;
    margin-bottom: calc(var(--baseline)*4) !important;
}
.container-home {
    text-align: center;
    margin-top: calc(var(--baseline)*6);
}
.container-button {
    justify-content: center;
    display: flex;
    margin-bottom: calc(var(--baseline)*3);
}
.banner-home {
    width: 100%;
    margin: calc(var(--baseline)*2) auto calc(var(--baseline)*10);
}
.banner-home img {
    width: 100%;
}