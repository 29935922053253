/* Menu Container */
.menu-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: white;
    z-index: 1000;
    display: none;
    justify-content: center;
    align-items: flex-start;
    min-width: 480px;
}

.menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.menu-container.open {
    display: flex;
    justify-content: space-between;
}

.menu-container ul, .menu-container li {
    margin: 0;
    padding: 0;
    list-style-type: none;
    line-height: calc(var(--baseline)*11);
}

.menu li a {
    text-decoration: none;
}

/* Menu */
.menu-container .menu {
    margin: calc(var(--baseline)*20) calc(var(--baseline)*10);
    font-size: 2.49rem;
}

.menu li:last-child {
    border-bottom: none;
}

.close-icon {
    height: calc(var(--baseline)*10);
    transform: rotate(45deg);
}

.close-button {
    background: none;
    border: none;
    font-size: 2.5em;
    cursor: pointer;
    text-align: right;
    line-height: 1;
    justify-content: flex-end;
    flex:1;
    padding: calc(var(--baseline)*1);
    align-items: flex-end;
}

.green {
    color: var(--menu-color);
}

.menu-container .menu-options {
    margin-top: calc(var(--baseline)*8);
}

.menu li a.grey {
    color: var(--grey-color);
}

.menu li a.menu-go-to-gdr {
    text-decoration: underline;
    font-size: 2.07rem;
}

.menu {
    animation-duration: 1.5s;
    animation-fill-mode: both;
}

.menu-container.slideRight {
    animation: slideRight 0.35s ease forwards;
}


@keyframes slideRight {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideLeft {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
@media screen and (max-width: 500px) {
    .menu-container {
        min-width: unset;
    }
    .menu-container ul, .menu-container li {
        line-height: calc(var(--baseline) * 10);
    }
    .menu-container .menu {
        margin: calc(var(--baseline)*15) calc(var(--baseline)*5);
        line-height: calc(var(--baseline)*8);
        font-size: 2.3rem;
    }
    .menu li a.menu-go-to-gdr {
        font-size: 1.7rem;
    }
    .close-icon {
        height: calc(var(--baseline)*6);
    }
}
